.catalog{
	.view{
		padding: 70px 0!important;
	}

	.productTitle {
		.ant-divider{
			background: #49274A;
		}
	}
	.brand{
		text-align: center;
		.img{
			width: 200px;
			padding: 15px 0;
		}
	}
	.product{
		padding: 5px;
		position: relative;
		.ant-card-cover{
			padding: 40px 0px 0px;
		}
		.productItem{
			.img {
				width: 100%;
				height: 260px;
				padding: 10px;
				background-size: contain!important;
				background-repeat: no-repeat!important;
			}
		}
		.ant-card-meta-title{
			color: #611872!important;
		}
		.slick-dots{
			li{
				background: #e0e0e0;
				&.slick-active{
					button{
						background: #611872;
					}
				}
			}
		}
		.ant-card-actions{
			li{
				span:hover{
				color: #611872;
				}
			}
		}
		.ant-card-meta-description{
				height: 105px;
				overflow-y: scroll;
		}
		.brand{
			position: absolute;
			right: 10px;
			top: 10px;
			width: 70px;
			z-index: 999;
			border-radius: 5%;
			img{
				width: 100%;
				z-index: 9999;
				padding: 5px;
			}
			&.horus{
				width: 60px;
			}
			&.ls{
				width: 50px;
			}
			&.generalCable{
				width: 100px;
			}
		}
		.category{
			z-index: 999;
			position: absolute;
			left: 10px;
			top: 10px;
			padding: 5px;
			span{
				font-size: 25px;
				color: #e0e0e0;
			}
		}
	}
	.filters{
		.brands, .filter{
			margin: 40px 0;
			.ant-divider{
				.ant-divider-inner-text{
					font-size: 25px;
				}
				&:before, &:after{
					    border-top: 1px solid #49274A;
				}
			}
		}
		.brands {
			.brand{
				padding: 10px;
				text-align: center;
				cursor: pointer;
				.img{
					width: 100%;
					-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
					filter: grayscale(100%);
					&.horus, &.ls{
						width: 60px;
					}
					&:hover {
						-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
						filter: grayscale(0%);
					}
				}
				&.selected{
					.img{
						-webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
						filter: grayscale(0%);
					}
				}
			}
		}
		.filter{
			padding: 10px;
			.ant-radio-button-wrapper-checked{
				background: #611872!important;
				border-color: #611872!important;
				-webkit-box-shadow: -1px 0 0 0 #feed17;
				box-shadow: -1px 0 0 0 #feed17;
				&.ant-radio-button-wrapper:hover{
					color: #fff;
				}
			}
			.ant-radio-button-wrapper:hover{
				color: #611872;
			}
		}
	}
}
.productModal{
	.catalogModal{
		.productItem{
			.img {
				width: 100%;
				height: 560px;
				padding: 10px;
				background-size: contain!important;
				background-repeat: no-repeat!important;
			}
		}

		.slick-dots{
			li{
				background: #e0e0e0!important;;
				&.slick-active{
					button{
						background: #611872!important;
					}
				}
			}
		}
	}
	.ant-modal-footer{
		.ant-btn{
			background: #611872;
			color: #fff;
			border-color: #611872;
			&:hover{
				color: #611872;
				background: #fff;
			}
			&:focus{
				color: #611872;
				background: #fff;
			}
		}
	}
}
.catalogAffix{
	margin-left: 2vw;
	button{
		background-color: #492649;
		border-color: #492649;
		&:hover, &:focus {
			background-color: #492649;
			border-color: #492649;			
		}
	}
}
.catalog-page{
	text-align: center;
	min-height: 50vh!important;
	.react-pdf__Page__canvas{
		margin: 0 auto;
	}
	.loadingPDF{
		color: #611872;
		top: calc(25vh - 34px);
		position: relative;
		i{
			background-color: #611872;
		}
	}
}
.pages{
	text-align: center;
	padding: 15px;
	.ant-pagination-item-active{
		border-color: #611872;
	}
	.ant-pagination-item-active,
	.ant-pagination-item,
	.ant-pagination-prev,
	.ant-pagination-next,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	.ant-pagination-options-quick-jumper input{
		a{
			color: #611872;
		}
		&:hover, &:focus{
			border-color: #611872;
			a, i{
				color: #611872;
				border-color: #611872;
			}
		}
	}
	.ant-pagination-options-quick-jumper input{
		&:hover, &:focus{
			-webkit-box-shadow: 0 0 0 2px #61187233;
			box-shadow: 0 0 0 2px #61187233;
		}
	}
}