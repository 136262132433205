$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-apple: "\e906";
$icon-agronomy: "\e907";
$icon-fabric: "\e908";
$icon-electric-tower: "\e909";
$icon-mining: "\e90a";
$icon-drinks: "\e90b";
$icon-water: "\e900";
$icon-helmet: "\e901";
$icon-medicion: "\e902";
$icon-maniobra: "\e903";
$icon-herramientas: "\e904";
$icon-automatizacion: "\e905";
$icon-residencial: "\e90c";
$icon-tools-2: "\e90d";

