
.home{
	.slider {
		width: 100%;
		position: relative;
		text-align: center;
		background: #000;
		
		.overlay-black{
			width: 100vw;
			height: 100vh;
			background: #000;
			position: absolute;
			top: 0;
			opacity: 0.3;
		}
		.overlay-blue{
			width: 100vw;
			height: 100vh;
			background: #fff;
			position: absolute;
			top: 0;
			opacity: 0.5;
		}
		.overlay-purple{
			width: 100vw;
			height: 100vh;
			background: #49274A;
			position: absolute;
			top: 0;
			opacity: 0.5;
		}
		.slick-list{
			height: 100vh;
		}
		.logo-wrapper{
			width: 100%;
			position: absolute;
			top: 0;
			height: 100vh;
			.logo {
				width: 60vw;
				height: 100%;
			}
		}
		.img{
			width: 100vw;
			height: 100vh;
			filter: blur(4px);
			-webkit-filter: blur(4px);
			background-size: cover;
			background-position: center;
			background-image: url(../images/banner1.jpg);
			&.banner2{
				background-image: url(../images/banner2.jpg);
			}
			&.banner3{
				background-image: url(../images/banner3.jpg);
				background-position: left;
			}
		}
	}

	.brands{
		padding: 60px 0;
		.title{
			font-size: 60px;
			color: #49274A;
			padding-bottom: 30px;
			font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
			.ant-divider{
				margin: 50px 0;
				background: #49274A;
			}
		}
		.brandWrapper{
			font-size: 20px;
			padding: 40px 0;
			img{
				width: 200px;
				margin: 0 auto;
				display: block;
			}
			.ant-divider{
				margin: 0;
				background: #49274A;
			}
			.brandLogo{
				margin-bottom: 30px;
			}
		}
		.moreBrands{
			button{
				margin: 0 auto;
				display: block;
				background: #49274A;
				border-color: #49274A;
				width: 60px;
				height: 60px;
				font-size: 30px;
			}
		}
	}
	.categories{
		padding: 60px 0;
		background: #49274A;
		.title{
			font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
			.ant-divider{
				.ant-divider-inner-text{
					color: #fff;
					font-size: 60px;
				}
				margin: 50px 0;
				background: #49274A;
			}
		}
		.categoryWrapper{
			.category{
				color: #fff;
				text-align: center;
				font-size: 20px;
				margin-top: 80px;
				.category-icon{
					font-size: 60px;
				}
				.category-title{
					padding: 30px;
				}
			}
		}
	}
	.difference{
		padding: 60px 0;
		.title{
			font-size: 60px;
			color: #49274A;
			padding-bottom: 30px;
			font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
			.ant-divider{
				margin: 50px 0;
				background: #49274A;
			}
		}
		.events{
			background: #49274A;
			height: 300px;
			img{
				height: 300px;
				margin: 0 auto;
			}
		}
	}
}