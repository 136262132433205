$header: 69px;
$footer: 97px;

html{
	scroll-behavior: smooth;
}

.menuSide{
	text-align: right;
	position: absolute!important;
	z-index: 9999;
	padding: 10px!important;
	.ant-btn{
		color: #611872;
		font-size: 20px;
		padding: 0 5px;
		&:hover{
			color: #fff;
		}
		&:focus{
			color: #fff;
		}
	}
	.ant-divider{
		background: #611872;
	}
}
@media only screen and (max-width: 576px) {
	.menuSide{
		text-align: center;
		.link{
			font-size: 12px;
		}
	}
}
.ant-layout-footer{
	background: #49274A!important;
	.footer{
		color: #fff;
		font-size: 24px;
		font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif;
		.icon{
			padding: 0 5px;
			color: #fff;
		}
		.social{
			text-align: right;
			font-size: 30px;
		}
		.copyright{
			padding: 5px 0;
			.icon{
				font-size: 14px;
				vertical-align: super;
			}
		}
	}
}

.ant-layout-content{
	background: #F4F4F4;
	min-height: calc(100vh - #{$footer})!important;
	font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif;	
}

.menu{
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 999;
	color: white;
	font-size: 25px;
}

.menuWrapper{
	width: 200px;
	.ant-popover-arrow{
		border-top-color: #001429!important;
		border-left-color: #001429!important;
		
	}
	.ant-menu-item-selected{
		background-color: #01aeef!important;
	}
	.ant-popover-inner-content{
		padding: 0;
	}
}

.layoutBanner{
	height: 30vh;
	overflow: hidden;
	.bannerTitle{
		position: absolute;
		top: calc(50% - 30px);
		color: #49274a;
		text-align: center;
		width: 100%;
		font-size: 60px;
	}
	.img{
		width: 100vw;
		height: 30vh;
		filter: blur(4px);
		-webkit-filter: blur(4px);
		background-size: cover;
		background-position: center;
	}
	.overlay-black{
		width: 100vw;
		height: 30vh;
		background: #000;
		position: absolute;
		top: 0;
		opacity: 0.3;
	}
	.overlay-blue{
		width: 100vw;
		height: 30vh;
		background: #fff;
		position: absolute;
		top: 0;
		opacity: 0.5;
	}
	.back{
		display: none;
	}
	.about{
		background-image: url(../images/about.jpg);
	}
	.contact{
		background-image: url(../images/contact.jpg);
	}
	.catalog, .general{
		background-image: url(../images/catalog.jpg);
	}
	&.generalWrapper{
		height: 10vh;
		.img, .overlay-black, .overlay-blue{
			height: 10vh;
		}
		.bannerTitle{
			font-size: 30px;
			top: calc(50% - 15px);
		}
		.back{
			display: block;
			position: absolute;
			top: calc(50% - 15px);
			padding: 0;
			cursor: pointer;
			z-index: 9999;
			.ant-btn{
				color: #611872;
				&:hover{
					color: #fff;
				}
				&:focus{
					color: #fff;
				}
			}
		}
	}
}
		












