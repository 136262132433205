@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?mb3l4y");
  src: url("fonts/icomoon.eot?mb3l4y#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?mb3l4y") format("truetype"), url("fonts/icomoon.woff?mb3l4y") format("woff"), url("fonts/icomoon.svg?mb3l4y#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-apple:before {
  content: ""; }

.icon-agronomy:before {
  content: ""; }

.icon-fabric:before {
  content: ""; }

.icon-electric-tower:before {
  content: ""; }

.icon-mining:before {
  content: ""; }

.icon-drinks:before {
  content: ""; }

.icon-water:before {
  content: ""; }

.icon-helmet:before {
  content: ""; }

.icon-medicion:before {
  content: ""; }

.icon-maniobra:before {
  content: ""; }

.icon-herramientas:before {
  content: ""; }

.icon-automatizacion:before {
  content: ""; }

.icon-residencial:before {
  content: ""; }

.icon-tools-2:before {
  content: ""; }

html {
  scroll-behavior: smooth; }

.menuSide {
  text-align: right;
  position: absolute !important;
  z-index: 9999;
  padding: 10px !important; }
  .menuSide .ant-btn {
    color: #611872;
    font-size: 20px;
    padding: 0 5px; }
    .menuSide .ant-btn:hover {
      color: #fff; }
    .menuSide .ant-btn:focus {
      color: #fff; }
  .menuSide .ant-divider {
    background: #611872; }

@media only screen and (max-width: 576px) {
  .menuSide {
    text-align: center; }
    .menuSide .link {
      font-size: 12px; } }

.ant-layout-footer {
  background: #49274A !important; }
  .ant-layout-footer .footer {
    color: #fff;
    font-size: 24px;
    font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif; }
    .ant-layout-footer .footer .icon {
      padding: 0 5px;
      color: #fff; }
    .ant-layout-footer .footer .social {
      text-align: right;
      font-size: 30px; }
    .ant-layout-footer .footer .copyright {
      padding: 5px 0; }
      .ant-layout-footer .footer .copyright .icon {
        font-size: 14px;
        vertical-align: super; }

.ant-layout-content {
  background: #F4F4F4;
  min-height: calc(100vh - 97px) !important;
  font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif; }

.menu {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
  color: white;
  font-size: 25px; }

.menuWrapper {
  width: 200px; }
  .menuWrapper .ant-popover-arrow {
    border-top-color: #001429 !important;
    border-left-color: #001429 !important; }
  .menuWrapper .ant-menu-item-selected {
    background-color: #01aeef !important; }
  .menuWrapper .ant-popover-inner-content {
    padding: 0; }

.layoutBanner {
  height: 30vh;
  overflow: hidden; }
  .layoutBanner .bannerTitle {
    position: absolute;
    top: calc(50% - 30px);
    color: #49274a;
    text-align: center;
    width: 100%;
    font-size: 60px; }
  .layoutBanner .img {
    width: 100vw;
    height: 30vh;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    background-size: cover;
    background-position: center; }
  .layoutBanner .overlay-black {
    width: 100vw;
    height: 30vh;
    background: #000;
    position: absolute;
    top: 0;
    opacity: 0.3; }
  .layoutBanner .overlay-blue {
    width: 100vw;
    height: 30vh;
    background: #fff;
    position: absolute;
    top: 0;
    opacity: 0.5; }
  .layoutBanner .back {
    display: none; }
  .layoutBanner .about {
    background-image: url(../images/about.jpg); }
  .layoutBanner .contact {
    background-image: url(../images/contact.jpg); }
  .layoutBanner .catalog, .layoutBanner .general {
    background-image: url(../images/catalog.jpg); }
  .layoutBanner.generalWrapper {
    height: 10vh; }
    .layoutBanner.generalWrapper .img, .layoutBanner.generalWrapper .overlay-black, .layoutBanner.generalWrapper .overlay-blue {
      height: 10vh; }
    .layoutBanner.generalWrapper .bannerTitle {
      font-size: 30px;
      top: calc(50% - 15px); }
    .layoutBanner.generalWrapper .back {
      display: block;
      position: absolute;
      top: calc(50% - 15px);
      padding: 0;
      cursor: pointer;
      z-index: 9999; }
      .layoutBanner.generalWrapper .back .ant-btn {
        color: #611872; }
        .layoutBanner.generalWrapper .back .ant-btn:hover {
          color: #fff; }
        .layoutBanner.generalWrapper .back .ant-btn:focus {
          color: #fff; }

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url("fonts/SourceSansPro-Bold.ttf"); }

@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url("fonts/SourceSansPro-Regular.ttf"); }

.home .slider {
  width: 100%;
  position: relative;
  text-align: center;
  background: #000; }
  .home .slider .overlay-black {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: absolute;
    top: 0;
    opacity: 0.3; }
  .home .slider .overlay-blue {
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: absolute;
    top: 0;
    opacity: 0.5; }
  .home .slider .overlay-purple {
    width: 100vw;
    height: 100vh;
    background: #49274A;
    position: absolute;
    top: 0;
    opacity: 0.5; }
  .home .slider .slick-list {
    height: 100vh; }
  .home .slider .logo-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100vh; }
    .home .slider .logo-wrapper .logo {
      width: 60vw;
      height: 100%; }
  .home .slider .img {
    width: 100vw;
    height: 100vh;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    background-size: cover;
    background-position: center;
    background-image: url(../images/banner1.jpg); }
    .home .slider .img.banner2 {
      background-image: url(../images/banner2.jpg); }
    .home .slider .img.banner3 {
      background-image: url(../images/banner3.jpg);
      background-position: left; }

.home .brands {
  padding: 60px 0; }
  .home .brands .title {
    font-size: 60px;
    color: #49274A;
    padding-bottom: 30px;
    font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif; }
    .home .brands .title .ant-divider {
      margin: 50px 0;
      background: #49274A; }
  .home .brands .brandWrapper {
    font-size: 20px;
    padding: 40px 0; }
    .home .brands .brandWrapper img {
      width: 200px;
      margin: 0 auto;
      display: block; }
    .home .brands .brandWrapper .ant-divider {
      margin: 0;
      background: #49274A; }
    .home .brands .brandWrapper .brandLogo {
      margin-bottom: 30px; }
  .home .brands .moreBrands button {
    margin: 0 auto;
    display: block;
    background: #49274A;
    border-color: #49274A;
    width: 60px;
    height: 60px;
    font-size: 30px; }

.home .categories {
  padding: 60px 0;
  background: #49274A; }
  .home .categories .title {
    font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif; }
    .home .categories .title .ant-divider {
      margin: 50px 0;
      background: #49274A; }
      .home .categories .title .ant-divider .ant-divider-inner-text {
        color: #fff;
        font-size: 60px; }
  .home .categories .categoryWrapper .category {
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-top: 80px; }
    .home .categories .categoryWrapper .category .category-icon {
      font-size: 60px; }
    .home .categories .categoryWrapper .category .category-title {
      padding: 30px; }

.home .difference {
  padding: 60px 0; }
  .home .difference .title {
    font-size: 60px;
    color: #49274A;
    padding-bottom: 30px;
    font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif; }
    .home .difference .title .ant-divider {
      margin: 50px 0;
      background: #49274A; }
  .home .difference .events {
    background: #49274A;
    height: 300px; }
    .home .difference .events img {
      height: 300px;
      margin: 0 auto; }

.about {
  font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif; }
  .about .slogan, .about .title {
    font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
    text-align: center;
    width: 100%;
    font-size: 40px; }
  .about .view {
    padding: 70px 0 !important; }
  .about .slogan {
    color: #49274A;
    padding: 0 0 50px; }
  .about .title {
    padding: 0 0 30px; }
  .about .mvContainer {
    background: #49274A;
    margin: 10px 0; }
    .about .mvContainer .left, .about .mvContainer .right {
      padding: 20px; }
    .about .mvContainer .left {
      color: #49274A;
      background: #fff; }
    .about .mvContainer .right {
      color: #fff; }
    .about .mvContainer .content {
      font-size: 16px;
      min-height: 120px; }
  .about .valores {
    padding: 20px; }
    .about .valores .list {
      text-align: center; }
    .about .valores .title .ant-divider .ant-divider-inner-text {
      color: #49274A;
      font-size: 40px; }
    .about .valores .title .ant-divider:before, .about .valores .title .ant-divider:after {
      border-color: #49274A; }
  .about .history {
    padding: 20px;
    color: #49274A;
    font-size: 16px; }
    .about .history .ant-divider {
      margin: 30px 0;
      background: #49274A; }
    .about .history .title {
      text-align: left; }
    .about .history .content {
      padding: 0; }
    .about .history .logo img {
      padding: 10% 0;
      width: 80%;
      margin: 0 auto;
      display: block; }

.contact .view {
  padding: 10px; }
  .contact .view .title {
    font-size: 40px;
    color: #49274A;
    padding-bottom: 30px;
    font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif; }
    .contact .view .title .ant-divider {
      margin: 35px 0;
      background: #49274A; }
  .contact .view .slogan {
    text-align: center;
    color: #49274a; }
  .contact .view .section {
    padding-top: 40px;
    padding-bottom: 40px; }
    .contact .view .section .subSection {
      padding-bottom: 20px; }
  .contact .view .text {
    font-size: 16px;
    padding-left: 10px; }
    .contact .view .text .ant-divider {
      background: #49274A; }
  .contact .view .h4Title {
    color: #49274A;
    padding-top: 10px; }
  .contact .view .form {
    padding-bottom: 10px; }
    .contact .view .form .ant-form-item-label > label {
      color: #49274a; }
    .contact .view .form .ant-form-item {
      margin-bottom: 5px; }
    .contact .view .form .formField {
      padding: 0 5px; }
      .contact .view .form .formField button {
        width: 100%;
        background-color: #49274a;
        border-color: #49274a; }
      .contact .view .form .formField .ant-input:focus, .contact .view .form .formField .ant-input:hover {
        border-color: #49274a;
        -webkit-box-shadow: 0 0 0 2px rgba(73, 39, 74, 0.2);
        box-shadow: 0 0 0 2px rgba(73, 39, 74, 0.2); }

.catalog .view {
  padding: 70px 0 !important; }

.catalog .productTitle .ant-divider {
  background: #49274A; }

.catalog .brand {
  text-align: center; }
  .catalog .brand .img {
    width: 200px;
    padding: 15px 0; }

.catalog .product {
  padding: 5px;
  position: relative; }
  .catalog .product .ant-card-cover {
    padding: 40px 0px 0px; }
  .catalog .product .productItem .img {
    width: 100%;
    height: 260px;
    padding: 10px;
    background-size: contain !important;
    background-repeat: no-repeat !important; }
  .catalog .product .ant-card-meta-title {
    color: #611872 !important; }
  .catalog .product .slick-dots li {
    background: #e0e0e0; }
    .catalog .product .slick-dots li.slick-active button {
      background: #611872; }
  .catalog .product .ant-card-actions li span:hover {
    color: #611872; }
  .catalog .product .ant-card-meta-description {
    height: 105px;
    overflow-y: scroll; }
  .catalog .product .brand {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 70px;
    z-index: 999;
    border-radius: 5%; }
    .catalog .product .brand img {
      width: 100%;
      z-index: 9999;
      padding: 5px; }
    .catalog .product .brand.horus {
      width: 60px; }
    .catalog .product .brand.ls {
      width: 50px; }
    .catalog .product .brand.generalCable {
      width: 100px; }
  .catalog .product .category {
    z-index: 999;
    position: absolute;
    left: 10px;
    top: 10px;
    padding: 5px; }
    .catalog .product .category span {
      font-size: 25px;
      color: #e0e0e0; }

.catalog .filters .brands, .catalog .filters .filter {
  margin: 40px 0; }
  .catalog .filters .brands .ant-divider .ant-divider-inner-text, .catalog .filters .filter .ant-divider .ant-divider-inner-text {
    font-size: 25px; }
  .catalog .filters .brands .ant-divider:before, .catalog .filters .brands .ant-divider:after, .catalog .filters .filter .ant-divider:before, .catalog .filters .filter .ant-divider:after {
    border-top: 1px solid #49274A; }

.catalog .filters .brands .brand {
  padding: 10px;
  text-align: center;
  cursor: pointer; }
  .catalog .filters .brands .brand .img {
    width: 100%;
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%); }
    .catalog .filters .brands .brand .img.horus, .catalog .filters .brands .brand .img.ls {
      width: 60px; }
    .catalog .filters .brands .brand .img:hover {
      -webkit-filter: grayscale(0%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(0%); }
  .catalog .filters .brands .brand.selected .img {
    -webkit-filter: grayscale(0%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(0%); }

.catalog .filters .filter {
  padding: 10px; }
  .catalog .filters .filter .ant-radio-button-wrapper-checked {
    background: #611872 !important;
    border-color: #611872 !important;
    -webkit-box-shadow: -1px 0 0 0 #feed17;
    box-shadow: -1px 0 0 0 #feed17; }
    .catalog .filters .filter .ant-radio-button-wrapper-checked.ant-radio-button-wrapper:hover {
      color: #fff; }
  .catalog .filters .filter .ant-radio-button-wrapper:hover {
    color: #611872; }

.productModal .catalogModal .productItem .img {
  width: 100%;
  height: 560px;
  padding: 10px;
  background-size: contain !important;
  background-repeat: no-repeat !important; }

.productModal .catalogModal .slick-dots li {
  background: #e0e0e0 !important; }
  .productModal .catalogModal .slick-dots li.slick-active button {
    background: #611872 !important; }

.productModal .ant-modal-footer .ant-btn {
  background: #611872;
  color: #fff;
  border-color: #611872; }
  .productModal .ant-modal-footer .ant-btn:hover {
    color: #611872;
    background: #fff; }
  .productModal .ant-modal-footer .ant-btn:focus {
    color: #611872;
    background: #fff; }

.catalogAffix {
  margin-left: 2vw; }
  .catalogAffix button {
    background-color: #492649;
    border-color: #492649; }
    .catalogAffix button:hover, .catalogAffix button:focus {
      background-color: #492649;
      border-color: #492649; }

.catalog-page {
  text-align: center;
  min-height: 50vh !important; }
  .catalog-page .react-pdf__Page__canvas {
    margin: 0 auto; }
  .catalog-page .loadingPDF {
    color: #611872;
    top: calc(25vh - 34px);
    position: relative; }
    .catalog-page .loadingPDF i {
      background-color: #611872; }

.pages {
  text-align: center;
  padding: 15px; }
  .pages .ant-pagination-item-active {
    border-color: #611872; }
  .pages .ant-pagination-item-active a,
  .pages .ant-pagination-item a,
  .pages .ant-pagination-prev a,
  .pages .ant-pagination-next a,
  .pages .ant-pagination-jump-next a,
  .pages .ant-pagination-jump-prev a,
  .pages .ant-pagination-options-quick-jumper input a {
    color: #611872; }
  .pages .ant-pagination-item-active:hover, .pages .ant-pagination-item-active:focus,
  .pages .ant-pagination-item:hover,
  .pages .ant-pagination-item:focus,
  .pages .ant-pagination-prev:hover,
  .pages .ant-pagination-prev:focus,
  .pages .ant-pagination-next:hover,
  .pages .ant-pagination-next:focus,
  .pages .ant-pagination-jump-next:hover,
  .pages .ant-pagination-jump-next:focus,
  .pages .ant-pagination-jump-prev:hover,
  .pages .ant-pagination-jump-prev:focus,
  .pages .ant-pagination-options-quick-jumper input:hover,
  .pages .ant-pagination-options-quick-jumper input:focus {
    border-color: #611872; }
    .pages .ant-pagination-item-active:hover a, .pages .ant-pagination-item-active:hover i, .pages .ant-pagination-item-active:focus a, .pages .ant-pagination-item-active:focus i,
    .pages .ant-pagination-item:hover a,
    .pages .ant-pagination-item:hover i,
    .pages .ant-pagination-item:focus a,
    .pages .ant-pagination-item:focus i,
    .pages .ant-pagination-prev:hover a,
    .pages .ant-pagination-prev:hover i,
    .pages .ant-pagination-prev:focus a,
    .pages .ant-pagination-prev:focus i,
    .pages .ant-pagination-next:hover a,
    .pages .ant-pagination-next:hover i,
    .pages .ant-pagination-next:focus a,
    .pages .ant-pagination-next:focus i,
    .pages .ant-pagination-jump-next:hover a,
    .pages .ant-pagination-jump-next:hover i,
    .pages .ant-pagination-jump-next:focus a,
    .pages .ant-pagination-jump-next:focus i,
    .pages .ant-pagination-jump-prev:hover a,
    .pages .ant-pagination-jump-prev:hover i,
    .pages .ant-pagination-jump-prev:focus a,
    .pages .ant-pagination-jump-prev:focus i,
    .pages .ant-pagination-options-quick-jumper input:hover a,
    .pages .ant-pagination-options-quick-jumper input:hover i,
    .pages .ant-pagination-options-quick-jumper input:focus a,
    .pages .ant-pagination-options-quick-jumper input:focus i {
      color: #611872;
      border-color: #611872; }
  .pages .ant-pagination-options-quick-jumper input:hover, .pages .ant-pagination-options-quick-jumper input:focus {
    -webkit-box-shadow: 0 0 0 2px #61187233;
    box-shadow: 0 0 0 2px #61187233; }
