.contact{
	.view{
		padding: 10px;
		.title{
			font-size: 40px;
			color: #49274A;
			padding-bottom: 30px;
			font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
			.ant-divider{
				margin: 35px 0;
				background: #49274A;
			}
		}
		.slogan{
			text-align: center;
			color: #49274a;
		}
		.section{
			padding-top: 40px;
			padding-bottom: 40px;
			.subSection{
				padding-bottom: 20px;
			}
		}

		.text{
			font-size: 16px;
			padding-left: 10px;
			.ant-divider{
				background: #49274A;
			}
		}
		.h4Title{
			color: #49274A;
			padding-top: 10px;
		}
		.form{
			padding-bottom: 10px;
			.ant-form-item-label > label{
				color: #49274a;
			}
			.ant-form-item{
				margin-bottom: 5px;
			}
			.formField{
				padding: 0 5px;
				button{
					width: 100%;
					background-color: #49274a;
					border-color: #49274a;
				}
				.ant-input:focus, .ant-input:hover {
					border-color: #49274a;
					-webkit-box-shadow: 0 0 0 2px rgba(73, 39, 74, 0.2);
					box-shadow: 0 0 0 2px rgba(73, 39, 74, 0.2);
				}
			}
		}
	}
}
	