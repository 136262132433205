@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mb3l4y');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mb3l4y#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mb3l4y') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mb3l4y') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mb3l4y##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-apple {
  &:before {
    content: $icon-apple; 
  }
}
.icon-agronomy {
  &:before {
    content: $icon-agronomy; 
  }
}
.icon-fabric {
  &:before {
    content: $icon-fabric; 
  }
}
.icon-electric-tower {
  &:before {
    content: $icon-electric-tower; 
  }
}
.icon-mining {
  &:before {
    content: $icon-mining; 
  }
}
.icon-drinks {
  &:before {
    content: $icon-drinks; 
  }
}
.icon-water {
  &:before {
    content: $icon-water; 
  }
}
.icon-helmet {
  &:before {
    content: $icon-helmet; 
  }
}
.icon-medicion {
  &:before {
    content: $icon-medicion; 
  }
}
.icon-maniobra {
  &:before {
    content: $icon-maniobra; 
  }
}
.icon-herramientas {
  &:before {
    content: $icon-herramientas; 
  }
}
.icon-automatizacion {
  &:before {
    content: $icon-automatizacion; 
  }
}
.icon-residencial {
  &:before {
    content: $icon-residencial; 
  }
}
.icon-tools-2 {
  &:before {
    content: $icon-tools-2; 
  }
}

