.about{
	font-family: SourceSansPro-Regular, Helvetica, Arial, sans-serif;
	.slogan, .title{
		font-family: SourceSansPro-Bold, Helvetica, Arial, sans-serif;
		text-align: center;
		width: 100%;
		font-size: 40px;
	}

	.view{
		padding: 70px 0!important;
	}

	.slogan{
		color: #49274A;
		padding: 0 0 50px;
	}

	.title{
		padding: 0 0 30px;
	}

	.mvContainer{
		background: #49274A;
		margin: 10px 0;
		
		.left, .right{
			padding: 20px;
		}
		.left{
			color: #49274A;
			background: #fff;
		}
		
		.right{
			color: #fff;
		}
		.content{
			font-size: 16px;
			min-height: 120px;
		}
	}
	
	.valores{
		padding: 20px;
		.list{
			text-align: center;
		}
		.title{
			.ant-divider{
				.ant-divider-inner-text{
					color: #49274A;
					font-size: 40px;
				}
				&:before, &:after{
					border-color: #49274A;
				}
			}
		}
	}
	
	.history{
		padding: 20px;
		color: #49274A;
		font-size: 16px;

		.ant-divider{
			margin: 30px 0;
			background: #49274A;
		}
		.title{
			text-align: left;
		}
		.content{
			padding: 0;
		}
		.logo{
			img{
				padding: 10% 0;
				width: 80%;
				margin: 0 auto;
				display: block;
			}
		}
	}

}